import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/92/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                    Rozjasňující oční krém
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/3574661352527_Neutrogena Hydro Boost Ocni krem_BOX+TUBA.jpg"
                      alt="NEUTROGENA HydroBoost krem na oci 15ml 500x"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                      Rozjasňující oční krém
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>Rozjasněte unavené oči pomocí NEUTROGENA® Hydro Boost® Rozjasňujícího očního krému, který je navržen tak, aby dodal jemné pleti okolo očí osvěžující dávku hydratace a pomohl posílit odolnost kožní bariéry. Rozjasní unavené oči a dodá pleti svěží vzhled a intenzivní hydrataci po celý den.</p>
                      <p>&nbsp;</p>
                      <ul className="tablelist">
                      <li>Přirozeně zadržuje vlhkost tak, jak vaše pokožka potřebuje.</li>
                      <li>Kyselina hyaluronová intenzivně hydratuje.</li>
                      <li>Trehalóza přírodního původu pomáhá uzamykat hydrataci v pokožce.</li>
                      <li>Jemná pleť okolo očí je na pohled zdravá, hydratovaná a je odolnější vůči ztrátě vlhkosti.</li>
                      </ul>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Výsledky:</p>
                      <ul className="tablelist">
                      <li>Zlepšuje hydrataci pleti již po první aplikaci – klinicky potvrzeno</li>
                      <li>Funguje na pokožce jako ochranná vrstva, která brání ztrátě vlhkosti.</li>
                      <li>Zanechává pleť hydratovanou po 24 hodin, čímž zabraňuje ztrátě pružnosti a udržuje svěží vzhled po celý den.</li>
                      </ul>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Při dlouhodobém používání:</p>
                      <ul className="tablelist">
                      <li>„90 % žen souhlasilo, že po jednom týdnu používání je jemná pleť očního okolí hebká* * Sebehodnocení, 32 žen používajících přípravek jednou denně“</li>
                      <li>Díky inteligentní kombinaci dvou složek, které vážou vodu, pomáhá tento krém ochránit kožní bariéru. Jemná pleť okolo očí si tak dokáže udržet hydrataci, kterou by jinak ztratila.</li>
                      </ul>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Bez obsahu oleje</p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Oftalmologicky testováno.</p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Testováno dermatology</p>
                      <p className="updatepage">&nbsp;</p>
                      </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Jemně nanášejte konečky prstů od horní části lícní kosti krouživými pohyby okolo očí.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>15 ml</dd>
                  </dl>
                </div>
                <div id="fb_46"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
